import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import Lightbox from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
export const query = graphql`
  query {
    praxis: directusMediaCollection(name: {eq: "praxis"}) {
    ...LightboxGalleryGrid
    }
    portrait: directusMediaCollection(name: {eq: "portrait"}) {
      name
      media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 480, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const Img = makeShortcode("Img");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
  <HeaderMedia id={'img_20200815_101427_resized_20201003_121728913'} mdxType="HeaderMedia" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Praxis`}</h1>
      <h2 {...{
        "id": "chronik"
      }}>{`Chronik`}</h2>
      <p>{`Simone Hartmann, Physiotherapeutin, Heilpraktikerin ( sektoral-Physiotherapie) und Osteopathin, geboren 1979 in Schwelm. Nach dem Abitur am Märkischen Gymnasium in Schwelm folgte eine 3-jährige Ausbildung zur Physiotherapeutin an der Physiotherapieschule der St. Elisabeth-Stiftung in Bochum.`}</p>
      <p>{`Nach dem erfolgreichen Abschluss begann meine Arbeitspraxis als Physiotherapeutin im Medifit in Schwelm, die ich nach einem Jahr beendete. Es folgte eine 5-jährige Tätigkeit als Physiotherapeutin im Physio-Center Schütte-Noll in Wermelskirchen. Parallel dazu machte ich eine zweijährige Zertifikatsausbildung: Manuelle Therapie und KG-Geräte.`}</p>
      <p>{`Ab 2005 folgten weitere Fortbildungskurse in: Evidenzbasierter Praxis und Akupressur.`}</p>
      <p>{`2007 absolvierte ich die Fortbildung in der Sportphysiotherapie. Ab dem Jahr 2007 arbeitete ich als Physiotherapeutin in einer Praxis in Schwelm, die ich im Juni 2011 auf eigenen Wunsch verlassen habe, um in die Selbständigkeit zu wechseln.`}</p>
      <p>{`Im Jahr 2009 standen für mich Fortbildungskurse an in Kiefergelenksbehandlung bei cranio-mandibulären Dysfunktionen, Triggerpunkttherapie und die Weiterbildung als rückengerechter Personal Trainer.`}</p>
      <p>{`Der Fortbildungskurs für Kinesiotaping erfolgte im Jahr 2010..`}</p>
      <p>{`Im November 2011 habe ich die Weiterbildung in Manueller Lymphdrainage / Komplexer Physikalischer Entstauungstherapie erfogreich abgeschossen. Im Oktober 2011 begann ich meine 6-jährige Ausbildung zur Osteopathin an der STILL ACADEMY in Oberhausen. Diese habe ich im Mai 2017 erfolgreich als Osteopathin abgeschlossen und bin Mitglied im Berufsverband VOD.`}</p>
      <p>{`Die Fortbildungskurse" FDM - Fasziendistorsionsmodell nach Stephen Typaldos 1+2 " absolvierte ich im Februar 2019 und Juni 2019.`}</p>
      <p>{`Im September 2019 und März 2020 besuchte ich die Kurse" Pädiatrie in der Osteopathie Teil 1 und 2 " bei Noori Mitha in Hamburg.`}</p>
      <div style={{
        textAlign: 'center',
        width: '480px',
        margin: 'auto'
      }}>
    <Img fluid={props.data.portrait.media[0].file.localFile.childImageSharp.fluid} style={{
          borderBottom: '10px solid #6AB021'
        }} mdxType="Img" />
      </div>
      <hr></hr>
      <h2 {...{
        "id": "bilder-der-praxis"
      }}>{`Bilder der Praxis`}</h2>
      <Lightbox images={props.data.praxis.images} thumbnails={props.data.praxis.thumbnails} fromApi mdxType="Lightbox" />
      <hr></hr>
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      